import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-landing-home',
  templateUrl: './landing-home.component.html',
  styleUrls: ['./landing-home.component.scss']
})
export class LandingHomeComponent implements OnInit {

  constructor(private router: Router) { }

  activeNavbarEl = '';
  sidebarOpen = false;
  clipPathTopLeftRatios = [
    '0 5%, 100% 0, 100% 100%, 0% 100%',
    '0 10%, 100% 0, 100% 100%, 0% 100%',
    '0 15%, 100% 0, 100% 100%, 0% 100%',
    '0 20%, 100% 0, 100% 100%, 0% 100%',
    '0 25%, 100% 0, 100% 100%, 0% 100%'
  ];
  clipPathTopRightRatios = [
    '0 0, 100% 5%, 100% 100%, 0% 100%',
    '0 0, 100% 10%, 100% 100%, 0% 100%',
    '0 0, 100% 15%, 100% 100%, 0% 100%',
    '0 0, 100% 20%, 100% 100%, 0% 100%',
    '0 0, 100% 25%, 100% 100%, 0% 100%'
  ];
  clipPathBottomLeftRatios = [
    '0 0%, 100% 0, 100% 100%, 0% 95%',
    '0 0%, 100% 0, 100% 100%, 0% 90%',
    '0 0%, 100% 0, 100% 100%, 0% 85%',
    '0 0%, 100% 0, 100% 100%, 0% 80%',
    '0 0%, 100% 0, 100% 100%, 0% 75%'
  ];
  clipPathBottomRightRatios = [
    '0 0, 100% 0%, 100% 95%, 0% 100%',
    '0 0, 100% 0%, 100% 90%, 0% 100%',
    '0 0, 100% 0%, 100% 85%, 0% 100%',
    '0 0, 100% 0%, 100% 80%, 0% 100%',
    '0 0, 100% 0%, 100% 75%, 0% 100%'
  ];
  clipPathBothConvexRatios = [
    '0 0, 100% 5%, 100% 95%, 0% 100%',
    '0 0, 100% 10%, 100% 90%, 0% 100%',
    '0 0, 100% 15%, 100% 85%, 0% 100%',
    '0 0, 100% 20%, 100% 80%, 0% 100%',
    '0 0, 100% 25%, 100% 75%, 0% 100%'
  ];
  clipPathBothConcaveRatios = [
    '0 5%, 100% 0%, 100% 100%, 0% 95%',
    '0 10%, 100% 0%, 100% 100%, 0% 90%',
    '0 15%, 100% 10%, 100% 100%, 0% 85%',
    '0 20%, 100% 0%, 100% 100%, 0% 80%',
    '0 25%, 100% 0%, 100% 100%, 0% 75%'
  ];
  navbarEls = [
    'homeNavbar',
    'servicesNavbar',
    'pricingNavbar',
    'contactNavbar'
  ];

  sidebarEls = [
    'homeSidebar',
    'servicesSidebar',
    'pricingSidebar',
    'contactSidebar'
  ];

  setActiveRoute = (elName) => {
    document.getElementById(elName).style.borderBottom = '3px solid black';
    document.getElementById(elName + 'El').style.color = 'black';
    document.getElementById(elName + 'El').style.marginTop = '3px';
  }

  setActiveSidebarRoute = (elName) => {
    document.getElementById(elName).style.color = 'black';
  }

  resetActiveRoute = () => {
    this.navbarEls.forEach(elName => {
      document.getElementById(elName).style.borderBottom = '0px solid black';
      document.getElementById(elName + 'El').style.color = '#969696';
      document.getElementById(elName + 'El').style.marginTop = '0px';
    });
  }

  resetActiveSidebarRoute = () => {
    this.sidebarEls.forEach(elName => {
      document.getElementById(elName).style.color = '#969696';
    });
  }

  setActiveSidebarEl = (elName) => {
    document.getElementById(elName + 'Sidebar').style.color = 'black';
  }

  changeRoute = (activeNavbarEl) => {
    if (activeNavbarEl === 'contact') {
      document.getElementById('footer').scrollIntoView({ behavior: 'smooth' });
    } else {
      document.getElementById(activeNavbarEl).scrollIntoView({ behavior: 'smooth' });
    }
    console.log(activeNavbarEl);
    this.activeNavbarEl = activeNavbarEl;
    this.resetActiveRoute();
    this.setActiveRoute(activeNavbarEl + 'Navbar');
    // do smooth scroll stuff
  }

  changeRouteSidebar = (activeNavbarEl) => {
    if (activeNavbarEl === 'contact') {
      document.getElementById('footer').scrollIntoView({ behavior: 'smooth' });
    } else {
      document.getElementById(activeNavbarEl).scrollIntoView({ behavior: 'smooth' });
    }
    this.activeNavbarEl = activeNavbarEl;
    this.resetActiveSidebarRoute();
    this.setActiveSidebarRoute(activeNavbarEl + 'Sidebar');
    this.toggleModel(false);
  }

  toggleModel = (e) => {
    this.sidebarOpen = e;
    if (e) {
      console.log(this.activeNavbarEl);
      setTimeout(() => {
        this.setActiveSidebarEl(this.activeNavbarEl);
      }, 100);
    }
  }

  setBgHeight = () => {
    const windowHeight = document.getElementById('landingHomePageCont').offsetHeight;
    console.log(windowHeight);

    // bg settings

    document.getElementById('blueBgDiv').style.height = windowHeight / 4 + 'px';
    document.getElementById('orangeBgDiv').style.height = windowHeight / 2 + 'px';
    document.getElementById('pinkBgDiv').style.height = 'calc(' + windowHeight / 4 + 'px + 10vh)';

    document.getElementById('blueBgDiv').style.clipPath = 'polygon(100% 0, 100% 65%, 40% 100%, 0 100%, 0 0)';
    document.getElementById('orangeBgDiv').style.clipPath = 'polygon(62% 9%, 100% 20%, 100% 85%, 0 100%, 0 0)';
    document.getElementById('orangeBgDiv').style.marginTop = '-25vh';

    document.getElementById('pinkBgDiv').style.clipPath = 'polygon(100% 10%, 100% 100%, 0% 100%, 0 100%, 0 0)';
    document.getElementById('pinkBgDiv').style.marginTop = '-15vh';

  }

  ngOnInit(): void {
    console.log(window.innerWidth, window.innerHeight);
    this.activeNavbarEl = 'home';
    if (this.activeNavbarEl === '') {
      this.setActiveRoute('homeNavbar');
    } else {
      this.setActiveRoute(this.activeNavbarEl + 'Navbar');
    }
    setTimeout(() => {
      this.setBgHeight();
    }, 200);
  }

}
