<style>
  .mainCont {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0px;
    left: 0px;
    overflow: auto;
    overflow-x: hidden;
    background: #eee;
    font-family: 'Poppins', sans-serif;
  }
</style>


<div class="mainCont">
  <router-outlet></router-outlet>
</div>
