<div class="landingHomePageCont" id="landingHomePageCont">

  <!-- navbar -->

  <div class="landingNavbar row">
    <div (click)="changeRoute('home')" class="logo">
      <p class="black">Bell</p>
      <p class="orange">Coww</p>
    </div>
    <div *ngIf="sidebarOpen" class="sidebar">
      <div class="row">
        <div (click)="changeRoute('home')" class="logo col-xs-6">
          <p class="black">Bell</p>
          <p class="orange">Coww</p>
        </div>
        <img src="../../../assets/bx-x.svg" (click)="toggleModel(false)" class="col-xs-6"
             style="width: 22px;height: auto;margin-left: auto;margin-right: 50px;margin-top: auto;margin-bottom: auto;"
             alt="">
      </div>
      <div style="display: flex; flex-direction: column;padding-left: 5vw;padding-right: 5vw;margin-top: 5vh;">
        <p class="sidebarItem" (click)="changeRouteSidebar('home')" id="homeSidebar">Home</p>
        <p class="sidebarItem" (click)="changeRouteSidebar('services')" id="servicesSidebar">Services</p>
        <p class="sidebarItem" (click)="changeRouteSidebar('pricing')" id="pricingSidebar">Pricing</p>
        <p class="sidebarItem" (click)="changeRouteSidebar('contact')" id="contactSidebar">Contact</p>
      </div>
    </div>
    <div style="margin-left: auto;" (click)="changeRoute('home')" id="homeNavbar" class="navbarItemCont">
      <a id="homeNavbarEl" class="navbarItem">
        Home
      </a>
    </div>
    <div id="servicesNavbar" (click)="changeRoute('services')" class="navbarItemCont">
      <a id="servicesNavbarEl" class="navbarItem">
        Services
      </a>
    </div>
    <div id="pricingNavbar" (click)="changeRoute('pricing')" class="navbarItemCont">
      <a id="pricingNavbarEl" class="navbarItem">
        Pricing
      </a>
    </div>
    <div id="contactNavbar" (click)="changeRoute('contact')" style="margin-right: 50px;" class="navbarItemCont">
      <a id="contactNavbarEl" class="navbarItem">
        Contact
      </a>
    </div>
    <div (click)="toggleModel(true)" style="margin-right: 40px;" class="navbarItemContAlt">
      <img src="../../../assets/bx-menu.svg" style="width: 22px;height: auto;" alt="">
    </div>
  </div>

  <!-- navbarend -->
  <div class="space" style="height: 8vh"></div>

  <div class="container mt-5 " style="padding-top: 7vh; padding-bottom: 7vh;">
    <div class="row py-4 justify-content-center">
      <div class="col-lg-5 col-sm-12 col-xs-12 heroImageText ml-auto mr-auto">
        <h2 class="font-weight-bold text-light">
          Smart Website that get you more Business
        </h2>
        <ul class="text-light">
          <li>
            Advantage Over Competitors
          </li>
          <li>
            More Business
          </li>
          <li>
            Direct Interaction with the Customer from Website
          </li>
          <li>
            Analytics
          </li>
          <li>
            Less Stress
          </li>
        </ul>

        <button style="margin-top: 5vh" class="btn contactBtn">Schedule a Call Today!!</button>
      </div>

      <div class="col-lg-6 col-sm-11 col-xs-12 mx-auto">
        <img src="assets/responsive-artismotion.png" (click)="changeRouteSidebar('contact')" alt="" class="w-100 heroImage">
      </div>
    </div>
  </div>

<!--  <div class="section section-components section-dark">
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <div class="image-container">
          <img class="components-macbook" src="./assets/landing/presentation-page/laptop-basic.png" alt=""/>
          <img class="table-img" src="./assets/landing/presentation-page/table.jpg" alt=""/>
          <img class="share-btn-img" src="./assets/landing/presentation-page/share-btn.png" alt=""/>
          <img class="coloured-card-btn-img" src="./assets/landing/presentation-page/coloured-card-with-btn.png"
               alt=""/>
          <img class="coloured-card-img" src="./assets/landing/presentation-page/coloured-card.png" alt=""/>
          <img class="social-img" src="./assets/landing/presentation-page/social-row.png" alt=""/>
          <img class="pin-btn-img" src="./assets/landing/presentation-page/pin-btn.png" alt=""/>
        </div>
      </div>


      <div class="d-none d-md-block col-md-12 col-lg-4 ml-auto mr-auto">
        <div class="container basic-container">
          <h3 class="title hero-label">Smart Website that get you more Business </h3>
          <ul class="section-5">
            <li>Advantage Over Competitors</li>
            <li>More Business</li>
            <li>Direct Interaction with the Customer from Website</li>
            <li>Analytics</li>
            <li>Less Stress</li>
          </ul>
        </div>
        <a (click)="changeRoute('contact')" class="btn contactBtn">Schedule A Call Today!!</a>
      </div>
    </div>
  </div>-->


  <div class="row contentCont">
    <div class="col-xs-12 contentBox">
      <!-- <div style="background: #eee; width: calc(100% - 10vw); margin-left: 5vw; margin-right: 5vw;height: 40vw;margin-top: 10vh;"></div> -->
      <p class="contentHeading" style="text-align: left;padding-left: 5vw; padding-right: 15vw;margin-top: 10vh;">
        What you want ?</p>
      <div class="row" style="margin-top: 5vh; margin-bottom: 8vh; padding-left: 2.5vw;padding-right: 2.5vw;">
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 vertical contentBoxInner">
          <img src="../../../assets/landing/rank.svg" class="servicesIllustrations" alt="">
          <p class="contentHeadingSmall">
            Acquire a website that works for you and sets your business apart from the competition.
          </p>
          <p class="contentText">
            Whether you're starting a new business or have been running one for years,
            building an online presence can help you flourish.
            We create custom websites for your business that make this process fast, simple and stress-free for you,
            without getting your hands dirty.
          </p>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 vertical contentBoxInner">
          <img src="../../../assets/landing/best (1).svg" class="servicesIllustrations" alt="">
          <p class="contentHeadingSmall">
            A professionally designed website will that let your customers know that you mean business.
          </p>
          <p class="contentText">
            Stand out in the digital world with sleek designs and features that fulfill your customer's every need
            through an affordable website. Our team walks with you every step of the way, creating content that's
            custom-tailored to best represent your business on the internet.
          </p>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 vertical contentBoxInner">
          <img src="../../../assets/landing/tablet.svg" class="servicesIllustrations" alt="">
          <p class="contentHeadingSmall">
            A modern website that will attract more visitors, gain new customers, and grow your business.
          </p>
          <p class="contentText">
            Put your best foot forward with our SEO and marketing services to get the most value from your investment.
            Establish your presence in the digital world and watch your numbers improve as more people find your
            business online.
          </p>
        </div>
      </div>
    </div>
  </div>

  <div class="row contentCont" style="margin-top: 8vh;">
    <div class="col-xs-12 contentBox">
      <p class="contentHeading" style="text-align: left;padding-left: 5vw; padding-right: 15vw;margin-top: 10vh;">What's
        Preventing you from getting what you want ?</p>
      <!-- <div style="margin-left: auto;margin-right: auto;"> -->
      <p class="contentText" style="padding-left: 5vw;padding-right: 15vw;font-size: 14px;text-align: left;">
        Expensive quotes or sub-par designs can be a turnoff when selecting custom website designers.
        Likewise, website builders such as Wix & Squarespace tend to not be the finished products they present
        themselves to be.
        You’ll spend hours, days, and even weeks filling in the templates with your own designs & marketing content.
        Expect a steep learning curve.
      </p>
      <!-- </div> -->
      <div class="preventingImgCont">
        <img src="../../../assets/landing/1.jpeg" style="margin-left: 30px;" class="preventingImg" alt="">
        <img src="../../../assets/landing/2.jpeg" class="preventingImg" alt="">
      </div>
      <div class="row" style="margin-top: 0vh; padding-left: 2.5vw;padding-right: 2.5vw;margin-bottom: 10vh;">
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 vertical contentBoxInner">
          <p class="contentHeadingSmall">
            Save time and resources.
          </p>
          <p class="contentText">
            On a budget? You can leave the heavy-lifting to us. You don't need to spend time experimenting with designs
            and fiddling with button placements. Just choose the features you need, and Bellcoww will put them together
            for you.
          </p>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 vertical contentBoxInner">
          <p class="contentHeadingSmall">
            Get a finished product.
          </p>
          <p class="contentText">
            You'll get a fully functional website from end to end, with the right support. Be ensured that you'll
            receive the best content, ranging from text to graphic design in a seamless product for a great experience
            for the customer, and for you.
          </p>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 vertical contentBoxInner">
          <p class="contentHeadingSmall">
            Focus on what's important.
          </p>
          <p class="contentText">
            No one understands your business like you do - except us. Our close customer-service relationship will
            assure you of that. While we work on your requirements, you can continue lavishing your focus where it's
            needed the most - on your business.
          </p>
        </div>
      </div>
      <!-- <div class="row" style="height: 80px;background: #eee;margin-top: 15vh;border-bottom-left-radius: 5px;border-bottom-right-radius: 5px;"></div> -->
    </div>
  </div>

  <div class="row contentCont" id="services" style="height: auto;margin-top: 8vh;">
    <div class="col-xs-12 contentBox">
      <p class="contentHeading" style="text-align: left;padding-left: 5vw; padding-right: 15vw;margin-top: 10vh;">
        How We Help You Get What You Want
      </p>
      <p class="contentText" style="padding-left: 5vw;padding-right: 15vw;font-size: 14px;text-align: left;">
        With BellCoww’s web services, you’ll receive a professionally designed custom modern website at a price most
        design
        firms can’t compete with. Your website will truly represent your business’s personality and
        better position you to attract the customers needed to grow your business.
        Our range of services ensure that every step is taken care of from beginning to end.
        With BellCoww, you’ll get more than you expect.
      </p>
      <div class="row" style="margin-top: 5vh; margin-bottom: 10vh;padding-left: 2.5vw;padding-right: 2.5vw;">
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 vertical contentBoxInner">
          <img src="../../../assets/landing/web-dev.svg" class="servicesIllustrations" alt="">
          <p class="contentHeadingSmall">
            Web Design and Development
          </p>
          <p class="contentText">
            Websites can be one of your most cost-effective marketing tools.
            Together, we'll create visually stunning & responsive websites that leave lasting impressions.
            Custom integrations & applications, eCommerce shopping carts, payment applications, chat-bots, and more.
            Whatever your business needs, we've got you covered.
          </p>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 vertical contentBoxInner">
          <img src="../../../assets/landing/content-writing.svg" class="servicesIllustrations" alt="">
          <p class="contentHeadingSmall">
            Content Writing
          </p>
          <p class="contentText">
            The right content can position your organization as an industry leader.
            Aside from providing valuable information to your customers, it can keep your organization relevant online
            and in the marketplace.
            Our content writing services can manage your organization's blogs, newsletters, website content, & more.
          </p>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 vertical contentBoxInner">
          <img src="../../../assets/landing/seo.svg" class="servicesIllustrations" alt="">
          <p class="contentHeadingSmall">
            SEO and Optimization
          </p>
          <p class="contentText">
            Over 50% of all internet traffic are searches. Good SEO practices helps your audience find you on search
            engines.
            Our websites are built with best practice SEO techniques.
            We'll also provide you an SEO roadmap to ensure that your site is constantly improving its search rankings.
          </p>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 vertical contentBoxInner">
          <img src="../../../assets/landing/report.svg" class="servicesIllustrations" alt="">
          <p class="contentHeadingSmall">
            Analytics and Reports
          </p>
          <p class="contentText">
            Drawing from industry leading reporting tools, you'll have access to administrative dashboards that'll allow
            you to make sense of
            your data and visualize your marketing results.
          </p>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 vertical contentBoxInner">
          <img src="../../../assets/landing/branding.svg" class="servicesIllustrations" alt="">
          <p class="contentHeadingSmall">
            Branding and Graphic Design
          </p>
          <p class="contentText">
            People are far more likely to remember images over words. Your logo and your website’s images are truly
            worth a thousand words.
            Whether it's creating logos or impactful graphic images, our designers will strive to maximize your message.
          </p>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 vertical contentBoxInner">
          <img src="../../../assets/landing/marketing.svg" class="servicesIllustrations" alt="">
          <p class="contentHeadingSmall">
            Marketing
          </p>
          <p class="contentText">
            Reach your audience with Pay-per-click advertising, social media marketing, & email campaigning.
            Our team will implement a productive and cost-effective plan for your organization.
          </p>
        </div>
      </div>
    </div>
  </div>


  <div class="row contentCont" id="services" style="height: auto;margin-top: 8vh;">
    <div class="col-xs-12 contentBox">
      <p class="contentHeading" style="text-align: left;padding-left: 5vw; padding-right: 15vw;margin-top: 10vh;">
        Why to believe us?
      </p>
      <p class="contentText" style="padding-left: 5vw;padding-right: 15vw;font-size: 14px;text-align: left;">
        With BellCoww’s web services, you’ll receive a professionally designed custom modern website at a price most
        design
        firms can’t compete with. Your website will truly represent your business’s personality and
        better position you to attract the customers needed to grow your business.
        Our range of services ensure that every step is taken care of from beginning to end.
        With BellCoww, you’ll get more than you expect.
      </p>
      <div class="row" style="margin-top: 5vh; margin-bottom: 10vh;padding-left: 2.5vw;padding-right: 2.5vw;">
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 vertical contentBoxInner">
          <img src="assets/artismotion.png" class="servicesIllustrations-sites" alt="">
          <a href="https://artismotion.org/" class="contentHeadingSmall-sites">Art is Motion</a>
          <p class="contentText">
            This dance and yoga studio wanted to create a unique web presence that matched its growing business. They acquired to two new locations and needed something to generate more leads and keep current customers better informed. Aside from the design overhaul, we added a custom calendar, e-commerce, and live chat capabilities. There website is like an extra employee working for them around the clock. Overall, they are extremely pleased and feel they have the nicest dance studio website in their industry.

          </p>
          <button style="margin-top: auto;" onclick="window.open('https://artismotion.org/','_blank');" class="sendBtn">Check it Out..</button>

        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 vertical contentBoxInner">
          <img src="assets/dependablemailing.png" class="servicesIllustrations-sites" alt="">
          <a href="https://dependablemailing.com" class="contentHeadingSmall-sites">Dependable Mailing</a>
          <p class="contentText">
            This direct mailing service business realized it needed to migrate from its older legacy website to a more modern one. They wanted to stand out from their competitors. As such, their website and content were completely overhauled. Now they have a more professional look and content strategy that positions them as a market authority.
          </p>
          <button style="margin-top: auto;"  onclick="window.open('https://dependablemailing.com','_blank');" class="sendBtn">Check it Out..</button>
        </div>
        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 vertical contentBoxInner">
          <img src="assets/versu.png" class="servicesIllustrations-sites" alt="">
          <a href="https://verusstrength.com/" class="contentHeadingSmall-sites">Verus Strength and Conditioning</a>
          <p class="contentText">
            Recently, this group fitness gym rebranded their image. We created a new website, added live chat services, new content, session replay integrations, and call to action forms. The owner has seen improved website traffic and is able to directly reach out to new site visitors. The rebranding matched exactly what the owner wanted and better positions them for future growth. They are quite pleased with the changes.
          </p>
          <button style="margin-top: auto;"  onclick="window.open('https://verusstrength.com/','_blank');" class="sendBtn">Check it Out..</button>
        </div>
      </div>
    </div>
  </div>



  <div class="row contentCont" id="pricing" style="margin-top: 8vh;">
    <div class="col-xs-12 contentBox">
      <p class="contentHeading" style="text-align: left;padding-left: 5vw; padding-right: 15vw;margin-top: 10vh;">How do
        we charge ?</p>
      <p class="contentText"
         style="padding-left: 5vw;padding-right: 15vw;font-size: 14px;text-align: left;margin-bottom: 10vh;">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Harum libero ad aut in, debitis sed et tenetur quaerat.
        Quis, itaque! Quae maxime at ad, quos, inventore maiores itaque ex eius fugiat iste commodi, incidunt libero
        doloremque totam
        architecto suscipit numquam a quasi ipsum? Illo, quis odio? Reiciendis, modi veniam, doloribus autem labore ipsa
        inventore animi
        voluptatem similique numquam deleniti nobis nam molestiae consectetur eum temporibus corrupti placeat ea
        repellat pariatur!
        Consequatur fugit sunt consectetur possimus. Aut aperiam dolorem eum minima dolor sequi asperiores et ad
        adipisci odio.
        Quos placeat architecto hic, dolore, delectus praesentium distinctio quaerat impedit laborum ad expedita
        molestiae amet
        excepturi sed quasi dolores laboriosam obcaecati, assumenda unde! Distinctio, quidem. Ullam, corporis
        reprehenderit suscipit
        impedit autem saepe qui.
      </p>

      <div class="pricingTableCont">
        <div class="pricingTableHeadingCont row">
          <p class="pricingTableHeading col-xs-4"
             style="color: white; background: #0080f3;border-top-left-radius: 5px;border-bottom-left-radius: 5px;">
            Products
          </p>
          <p class="pricingTableHeading col-xs-4">
            Basic
          </p>
          <p class="pricingTableHeading col-xs-4">
            Pro
          </p>
        </div>
        <div class="pricingTableTextCont row" style="margin-top: 50px;">
          <p class="pricingTableText col-xs-4" style="color: black;">
            Website
          </p>
          <p class="pricingTableText col-xs-4">
            Free
          </p>
          <p class="pricingTableText col-xs-4">
            Free
          </p>
        </div>
        <div class="pricingTableTextCont row">
          <p class="pricingTableText col-xs-4" style="color: black;">
            Content
          </p>
          <p class="pricingTableText activePricingEl col-xs-4">
            8 USD <small style="margin-left: 10px; font-size: 11px; color: #666; font-weight: 500;">/mo</small>
          </p>
          <p class="pricingTableText activePricingEl col-xs-4">
            4 USD <small style="margin-left: 10px; font-size: 11px; color: #666; font-weight: 500;">/mo</small>
          </p>
        </div>
        <div class="pricingTableTextCont row">
          <p class="pricingTableText col-xs-4" style="color: black;">
            SEO
          </p>
          <p class="pricingTableText activePricingEl col-xs-4">
            5 USD <small style="margin-left: 10px; font-size: 11px; color: #666; font-weight: 500;">/mo</small>
          </p>
          <p class="pricingTableText activePricingEl col-xs-4">
            3 USD <small style="margin-left: 10px; font-size: 11px; color: #666; font-weight: 500;">/mo</small>
          </p>
        </div>
        <div class="pricingTableTextCont row">
          <p class="pricingTableText col-xs-4" style="color: black;">
            Analytics
          </p>
          <p class="pricingTableText activePricingEl col-xs-4">
            15 USD <small style="margin-left: 10px; font-size: 11px; color: #666; font-weight: 500;">/mo</small>
          </p>
          <p class="pricingTableText activePricingEl col-xs-4">
            12 USD <small style="margin-left: 10px; font-size: 11px; color: #666; font-weight: 500;">/mo</small>
          </p>
        </div>
        <div class="pricingTableTextCont row">
          <p class="pricingTableText col-xs-4" style="color: black;">
            Branding
          </p>
          <p class="pricingTableText activePricingEl col-xs-4">
            45 USD <small style="margin-left: 10px; font-size: 11px; color: #666; font-weight: 500;">/mo</small>
          </p>
          <p class="pricingTableText activePricingEl col-xs-4">
            40 USD <small style="margin-left: 10px; font-size: 11px; color: #666; font-weight: 500;">/mo</small>
          </p>
        </div>
        <div class="pricingTableTextCont row">
          <p class="pricingTableText col-xs-4" style="color: black;">
            Marketing
          </p>
          <p class="pricingTableText activePricingEl col-xs-4">
            25 USD <small style="margin-left: 10px; font-size: 11px; color: #666; font-weight: 500;">/mo</small>
          </p>
          <p class="pricingTableText activePricingEl col-xs-4">
            20 USD <small style="margin-left: 10px; font-size: 11px; color: #666; font-weight: 500;">/mo</small>
          </p>
        </div>
        <div class="pricingTableTextCont row" style="margin-top: 10vh; margin-bottom: 10vh;">
          <p class="pricingTableText col-xs-4"
             style="color: white; background: #0080f3;border-top-left-radius: 5px;border-bottom-left-radius: 5px;">
            Total
          </p>
          <p class="pricingTableText activePricingEl col-xs-4">
            100 USD <small style="margin-left: 10px; font-size: 11px; color: #666; font-weight: 500;">/mo</small>
          </p>
          <p class="pricingTableText activePricingEl col-xs-4">
            150 USD <small style="margin-left: 10px; font-size: 11px; color: #666; font-weight: 500;">/mo</small>
          </p>
        </div>
      </div>

      <!-- color: #0080f3; font-weight: 400; -->

    </div>
  </div>

  <!-- footer -->
  <div id="footer" class="row"
       style="background: #0080f3;margin-top: 10vh;padding-bottom: 10vh;padding-left: 5vw; padding-right: 5vw;height: auto;">
    <div class="col-md-3 col-sm-6 col-xs-12"
         style="display: flex; flex-direction: column;justify-content: flex-start;align-items: flex-start;margin-top: 10vh;">
      <!-- <div style="width: 100%;height: auto;min-height: 200px;background: transparent;">

      </div> -->
      <img src="../../../assets/landing/logo-color.png" style="width: 110px; margin-left: -10px; height: auto;" alt="">
      <div (click)="changeRoute('home')" style="margin-top: 0px;margin-left: 0px;" class="logo">
        <p class="black" style="font-size: 18px;color: white">Bell</p>
        <p class="orange" style="font-size: 18px;">Coww</p>
      </div>
      <p style="color: #ddd;font-size: 11px;font-weight: 500;padding-right: 30px;">Web Services that enable businesses
        to speak
        to their audience, grab their attention, and convert them to customers.</p>
      <p style="color: white; font-size: 13px;font-weight: 500;margin-bottom: 0px;">© 2020 Bellcoww</p>
    </div>
    <div class="col-md-1"></div>
    <div class="col-xs-12 col-sm-6 col-md-2"
         style="display: flex; flex-direction: column;justify-content: flex-start;align-items: flex-start;margin-top: 10vh;color: white;">
      <p style="margin-top: 0px;margin-bottom: 50px;font-size: 18px;font-weight: 500;">Sitemap</p>
      <p class="footerItem" (click)="changeRoute('home')">Home</p>
      <p class="footerItem" (click)="changeRoute('services')">Services</p>
      <p class="footerItem" (click)="changeRoute('pricing')">Pricing</p>
      <p class="footerItem" (click)="changeRoute('contact')">Contact</p>
    </div>

    <div class="col-md-5 col-sm-12 col-xs-12"
         style="display: flex; flex-direction: column; justify-content: flex-start;align-items: flex-start;margin-top: 10vh;color: white;">
      <p style="margin-bottom: 50px;margin-top: 0px;font-size: 18px;font-weight: 500;">Reach Us</p>
      <input style="margin-top: 0px;" type="text" placeholder="Your Name" class="input">
      <input type="text" placeholder="Email Address" class="input">
      <textarea name="userMsg" placeholder="Your Message" class="userMsg"></textarea>
      <button class="sendBtn">Send It</button>

    </div>
    <div class="col-md-1"></div>
  </div>
</div>

<div class="bgSvg" id="bgSvg">
  <div class="bgBlue" id="blueBgDiv"></div>
  <div class="bgOrange" id="orangeBgDiv"></div>
  <div class="bgPink" id="pinkBgDiv"></div>
</div>
